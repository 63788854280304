import { graphql } from 'gatsby'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'
import { Link } from '@reach/router'

interface Props {
  location: Location
}

const NotFound: React.FC<Props> = ({ location }: Props) => {

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Not Found" />
      <div>
        <section className="text-center">
          <div className="container">
            <h1>Ooops! Something hasn't gone as planned</h1>
            <p className="lead text-muted">
                It seems that the page you're looking for can't be found. Please 
                return to the <Link to="/">Home Page</Link>.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NotFound
